@font-face {
    font-family: 'SDSamliphopangche_Basic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Basic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSansKR-Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSansKR-ExtraLight';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-ExtraLight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'poppinsM';
    src: url('../font/Poppins-Medium.ttf');
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr {
    font-family: "Noto Sans KR", sans-serif;
}

::-webkit-scrollbar { 
    width: 8px;
}

::-webkit-scrollbar-track { 
    background-color: #8ec5fc;
}

::-webkit-scrollbar-thumb { 
    background: #5a5a5a;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #70a5da; 
}

::-webkit-scrollbar-thumb:active { 
    background: #3a5e81;
} 

::-webkit-scrollbar-button { display: none; }

.bs-all {
    max-width: 1920px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e0c3fc;
    background-image: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
}

.about {
    overflow: hidden;
    min-height: 80.7vh;
    height: fit-content;
    width: 100%;
}

.first {
    height: 100vh;
    padding: 5%;
}

.first-text {
    width: auto;
    height: fit-content;
    color: white;
    font-size: 9.5vh;
    margin-left: 10vh;
    font-family: 'SDSamliphopangche_Basic';
    margin-top: 2.5rem;
}

@keyframes spacing {
    0% {
        opacity: 0;
        color:#5a5a5a;
    }
    50% {
        opacity: 1;
        color: #000;
    }
    100% {
        opacity: 1;
        color: white;
    }
}

.first-text:hover {
    animation: spacing 2.2s ease-in-out;
}

.about-logo {
    margin-top: 0.5vh;
    margin-left: 10vh;
    width: 45vw;
    height: auto;
}

.first-details {
    font-size: 4vh;
    color: #5a5a5a;
    margin-left: 10vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 7vh;
}

.first-details>span:hover {
    background-color:rgb(243, 207, 0);
    color: white;
}

.about-kid {
    width: 255px;
    height: auto;
    float: right;
    margin-right: 2rem;
    opacity: 60%;
}

.artsection1{
    margin: 0 auto;
}

.art1{
    z-index: 2;
    position: relative;
    top: 43vh;
    left: 6vw;
    width: 50vw;
    /* box-shadow: -1.5px 1.5px 5px #000; */
}

.art2{
    z-index: 1;
    position: relative;
    top: 10vh;
    right: 6vw;
    width: 30vw;
    /* box-shadow: 1.5px -1.5px 5px #000; */
}

.second {
    background-color: white;
    padding: 5%;
    width: auto;
    height: fit-content;
    text-align: center;
}

.second-text {
    color: rgb(34, 34, 34);
    font-size: 7.3vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 2.7rem;
    line-height: 1.3em;
    text-align: center;
}

.second-text>span {
    background: linear-gradient(to right, rgb(255, 0, 119), rgb(38, 0, 255));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.second-details {
    font-size: 4vh;
    color: rgb(129, 129, 129);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 20rem;
    margin-bottom: 10vh;
    text-align: center;
}

.media-art {
    width: 50vw;
    height: 20vh;
}

.about-media1 {
    width: 42vw;
    height: auto;
    margin-top: 10vh;
    margin-left: 10vh;
}

.about-drawing {
    width: 50vw;
    height: auto;
    margin-top: 10vh;
    margin-right: 7vh;
    margin-bottom: 10vh;
    float: right;
}

.about-card {
    width: 50vw;
    height: auto;
    margin-top: 10vh;
    margin-left: 7vh;
    margin-bottom: 10vh;
}

.about-request {
    width: 50vw;
    height: auto;
    margin-top: 10vh;
    margin-left: 43vh;
    margin-bottom: 7vh;
}

.third {
    background-color: rgb(243, 243, 243);
    padding: 5%;
    width: auto;
    height: fit-content;
}

.third-text {
    color: rgb(34, 34, 34);
    font-size: 7.3vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 10vh;
    margin-left: 2rem;
    line-height: 1.3em;
}

.third-text>span {
    color: #fff;
    background: linear-gradient(to right,#095fab 10%, #25abe8 50%, #57d75b 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
}

@keyframes textclip {
   to {
      background-position: 200% center;
   }
}

.third-details {
    font-size: 4vh;
    color: rgb(129, 129, 129);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 6vh;
    margin-left: 10vh;
    margin-bottom: 10vh;
}

.about-lang {
    width: 28%;
    height: auto;
    float: right;
    margin-right: 2rem;
    opacity: 90%;
}

.fourth {
    background-color: white;
    padding: 5%;
    width: auto;
    height: 353vh;
}

.fourth-text {
    color: rgb(34, 34, 34);
    font-size: 8vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 10vh;
    margin-bottom: 15vh;
    text-align: center;
}

.fourth-text-ex {
    color: rgb(34, 34, 34);
    font-size: 8vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30vh;
}

.fourth-text-ex>span:nth-child(2) {
    color: rgb(80, 80, 80);
}

.fourth-text-ex>span:nth-child(3) {
    color: rgb(153, 153, 153);
}

.fourth-text-ex>span:nth-child(4) {
    color: rgb(212, 212, 212);
}

.fourth-text-ex>span:nth-child(5) {
    color: rgb(238, 238, 238);
}

.fourth-one {
    color: rgb(34, 34, 34);
    font-size: 7.3vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-left: 2rem;
    line-height: 1.3em;
}

.fourth-one> span {
    background: linear-gradient(to right,#27bbff, #3e00ce);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fourth-two {
    color: rgb(34, 34, 34);
    font-size: 7.3vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 10vh;
    margin-right: 2rem;
    line-height: 1.3em;
    text-align: right;
}

.fourth-two> span {
    background: linear-gradient(to right,#27bbff, #3e00ce);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fifth {
    background-color: rgb(243, 243, 243);
    padding: 5%;
    width: auto;
    height: fit-content;
}

.fifth-text {
    color: rgb(34, 34, 34);
    font-size: 9vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 2rem;
    margin-left: 2rem;
    line-height: 1.3em;
}

.fifth-text-big {
    margin-top: 10vh;
    text-align: right;
    margin-top: 0;
    margin-left: 0;
    margin-right: 2rem;
}

.fifth-details {
    color: rgb(34, 34, 34);
    font-size: 7.7vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 34vh;
    margin-left: 2rem;
    line-height: 1.3em;
}

.fifth-details>span {
    position: relative;
    text-decoration: none;
    color: white;
    display: inline-block;
    background-size: 120% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    -ms-background-clip: text;
    -ms-text-fill-color: transparent;
    background-clip: text;
    background-image: linear-gradient(45deg, #7794ff, #44107A,#FF1361,#FFF800);
    animation: 1s shake infinite alternate;
}

@keyframes shake {
    0% { transform: skewX(-15deg); }
    4% { transform: skewX(15deg); }
    8% { transform: skewX(-15deg); }
    12% { transform: skewX(15deg); }
    16% { transform: skewX(0deg); }
    100% { transform: skewX(0deg); }  
}

/* 굿즈 */
.sixth  {
    background-color: white;
    padding: 5%;
    width: auto;
    height: fit-content;
}

.sixth-text {
    color: rgb(34, 34, 34);
    font-size: 7vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 2.7rem;
    line-height: 1.3em;
}

.sixth-details {
    font-size: 4vh;
    color: rgb(129, 129, 129);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    line-height: 1.5em;
    margin-top: 1rem;
    margin-left: 2rem;
}

.goods-all{
    display: flex;
    /* flex-flow: row wrap; */
    width: 50vw;
    margin: 0 auto;
    margin-top: 15vh;
    margin-bottom: 9vh;
    text-align: center;
    justify-content: space-between;
}

.case{
    width: 13vw;
    object-fit: cover;
    border-radius: 70%;
    overflow: hidden;
}

.griptok{
    width: 13vw;
    object-fit: cover;
    border-radius: 70%;
    overflow: hidden;
}

.keyring{
    width: 13vw;
    object-fit: cover;
    border-radius: 70%;
    overflow: hidden;
}

.seventh {
    background-color: rgb(243, 243, 243);
    padding: 5%;
    width: auto;
    height: fit-content;
}

.seventh-text {
    color: rgb(34, 34, 34);
    font-size: 7vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 2.7rem;
    margin-bottom: 12vh;
    line-height: 3.4rem;
    text-align: center;
}

.seventh-text>span {
    background: linear-gradient(to right, rgb(126, 126, 126), rgb(66, 66, 66));
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.seventh-details {
    line-height: 1.5em;
    margin-top: 2.4rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.way {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 4.8vh;
    line-height: 2.4rem;
    margin: 10px 0;
}

.way-details {
    font-size: 2.2vh;
    color: rgb(129, 129, 129);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 1rem;
    margin-bottom: 3.3vh;
}

.way-details-more {
    color: rgb(26, 87, 201);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 400;
    font-size: 2.5vh;
    margin-bottom: 7vh;
}

.eighth {
    background-color: white;
    padding: 5%;
    width: auto;
    height: fit-content;
}

.eighth-text {
    color: rgb(34, 34, 34);
    font-size: 6.3vh;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
    margin-top: 2.7rem;
    margin-bottom: 9.3vh;
    line-height: 1.3em;
    text-align: center;
}

.eighth-details {
    line-height: 1.5em;
    margin-top: 1rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-intro {
    width: 40%;
    height: fit-content;
    margin: 10px;
}

.about-img {
    width: 35%;
    height: auto;
    margin: 10px;
}

.about-name {
    font-family: 'poppinsM';
    font-size: 3.3vh;
    margin: 10px 0;
}

.about-bio {
    font-size: 2.2vh;
    color: rgb(129, 129, 129);
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 1rem;
    margin-bottom: 7vh;
}
