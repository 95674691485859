@font-face {
  font-family: 'poppinsM';
  src: url('../font/Poppins-Medium.ttf');
}

.bs-all {
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e0c3fc;
    background-image: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
}

.shop {
    height: fit-content;
    width: 100%;
}

.shop1{
    width: 84%;
    margin: 0 auto;
    margin-top: 8.8vh;
    margin-left: 8.7vw;
    font-family: "Noto Sans KR", sans-serif;
}

.shop-text {
    color: rgb(34, 34, 34);
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'poppinsM';
}

.shop-all{
    margin: 0 auto;
    margin-top: 2vh;
    margin-bottom: 14vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
}

.shop-one {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 1.3vh;
}

.shop-two {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin-top: 13vh;
}

.shop-img{
    width: 26vw;
}

.main-text {
    text-align: left;
    font-size: 2.8vh;
    font-weight: 500;
    margin-top: 1.3vh;
    margin-left: 1vh;
    color: rgb(34, 34, 34);
}

.price-text {
    text-align: left;
    font-size: 2.4vh;
    font-weight: 400;
    margin-top: 0.3vh;
    margin-left: 1vh;
    color: rgb(54, 54, 54);
}