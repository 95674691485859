@font-face {
    font-family: 'poppinsM';
    src: url('../font/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'SDSamliphopangche_Basic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Basic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr {
    font-family: "Noto Sans KR", sans-serif;
}

* {
    box-sizing: border-box;
}
.all {
    height: 12vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
    z-index: 1;
}

.logo {
    width: 8.8vw;
    height: 4.7vh;
    margin-top: 4.8vh;
    margin-left: 4.4vh;
}

.Menu {
    display: inline-flex;
    position: relative;
    margin-top: 4.8vh;
    min-width: 34vw;
    padding: 0 3vh;
}

.MenuItem {
    color: #fff;
    padding: 1.7vh;
    text-decoration: none;
    transition: 0.3s;
    margin: 0 1.2vh;
    z-index: 1;
    font-weight: 500;
    font-family: 'poppinsM';
    font-size: 2.4vh;
    position: relative;
}

.MenuItem::before {
    content: "";
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: #5a5a5a;
    border-radius: 8px 8px 0 0;
    opacity: 0;
    transition: 0.3s;
}

.MenuItem:hover:before {
    opacity: 1;
    bottom: 0;
  }
  
.MenuItem:hover {
    color: #5a5a5a;
}

.MenuItem:active {
    color: #5a5a5a !important; 
}

.MenuIndicator {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4vh;
    transition: 0.4s;
    z-index: 1;
    border-radius: 8px 8px 0 0;
}

.request-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'SDSamliphopangche_Basic';
    font-size: 2.4vh;
    text-align: center;
    padding: 5px 15px;
    border: solid 3px white;
    border-radius: 5px;
    color:#fff;
    background-color: transparent;
    width: 7.2vw;
    height: fit-content;
    margin-top: 2.5%;
    margin-right: 2.2%;
    box-shadow: 1.5px 1.5px rgba(34, 35, 58, 0.2);
    cursor: pointer;
}

.request-btn:hover {
    color: #5a5a5a;
    border: solid 3px #5a5a5a;
}

.form-name {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: left;
    margin-left: 2vh;
    margin-top: 1vh;
    margin-bottom: 2vh;
}

.form-name input {
    height: auto;
    line-height: normal;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    width: 17.2vw;
    margin-left: 1.5vh;
    border: solid 1.2px #b6b6b6;
}

.form-email {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: left;
    margin-left: 2vh;
    margin-bottom: 2vh;
}

.form-email input {
    height: auto;
    line-height: normal;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    width: 20.3vw;
    margin-left: 1.5vh;
    border: solid 1.2px #b6b6b6;
}

.form-tel {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: left;
    margin-left: 2vh;
    margin-bottom: 2vh;
}

.form-tel input {
    height: auto;
    line-height: normal;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    width: 19.2vw;
    margin-left: 1.5vh;
    border: solid 1.2px #b6b6b6;
}

.form-number {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: left;
    margin-left: 2vh;
    margin-bottom: 2vh;
}

.form-number input {
    height: auto;
    line-height: normal;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    width: 19vw;
    margin-left: 1.5vh;
    border: solid 1.2px #b6b6b6;
}

.form-sub {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 2.5vh;
    text-align: left;
    margin-left: 2vh;
}

.form-sub textarea {
    height: auto;
    line-height: normal;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    min-width: 30vw;
    max-width: 30vw;
    min-height: 12vh;
    max-height: 12vh;
    border: solid 1.2px #b6b6b6;
    margin-top: 1vh;
    margin-left: 0.7vh;
}

.form-text {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    font-size: 2vh;
    text-align: left;
    color: #5a5a5a;
    margin-top: 5vh;
    margin-left: 2vh;
    margin-bottom: 1vh;
}

.form-text>a {
    color: rgb(26, 87, 201);
}
