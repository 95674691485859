@font-face {
    font-family: 'poppinsM';
    src: url('../../font/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'poppinsL';
    src: url('../../font/Poppins-Light.ttf');
}

.canvas-all {
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    align-items: center;
    margin: 0 auto;
    margin-top: 5vh;
}

.canvaspad {
    width: 60vw;
    height: 50vh;
    background: rgb(255, 255, 255);
    border: 1.2px dashed;
    margin-top: 7vh;
}

.canvas-save {
    font-family: 'poppinsM';
    float: right;
    color: #fff;
    padding: 5px;
    cursor: pointer;
}

.canvas-save:hover{
    color: #69c;
}

.canvas-clear {
    float: left;
    font-family: 'poppinsM';
    color: #fff;
    padding: 5px;
    cursor: pointer;
}

.canvas-clear:hover{
    color: #69c;
}