@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr {
    font-family: "Noto Sans KR", sans-serif;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
    outline: none;
    cursor: pointer;
    border: 0;
}
.modal > section {
    width: 90%;
    max-width: 450px;
    margin:0 auto;
    border-radius: .3rem;
    background-color: #fff;
    animation: modal-show .3s;
    overflow: hidden;
}
.modal > section > header {
    position: relative;
    padding: 2.8vh 0;
    background-color: #f1f1f1;
    font-weight: 700;
    font-family: "Noto Sans KR", sans-serif;
}
.modal > section > header button {
    position: absolute;
    top: 3.1vh;
    right: 3.1vh;
    width: auto;
    font-size: 2.2vh;
    font-weight: 900;
    font-family: "Noto Sans KR", sans-serif;
    text-align: center;
    color: #999;
    background-color: transparent;
}
.modal > section > main {
    padding: 16px;
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}
.modal > section > footer {
    padding: 12px 16px;
    text-align: right;
}
.modal > section > footer button {
    padding: 1vh 3vh;
    color: #fff;
    background-color: #5a5a5a;
    border-radius: 1vh;
    font-size: 2.1vh;
    font-family: "Noto Sans KR", sans-serif;
}
.modal.openModal {
    display: flex;
    align-items: center;
    animation: modal-bg-show .3s;
}
@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}
@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}