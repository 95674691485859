@font-face {
    font-family: "poppinsM";
    src: url("../font/Poppins-Medium.ttf");
}

@font-face {
    font-family: "poppinsL";
    src: url("../font/Poppins-Light.ttf");
}

@font-face {
    font-family: "116watermelon";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.0/116watermelon.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Pretty_Left_handed";
    src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/naverfont_09@1.0/Pretty_Left_handed.woff")
        format("woff");
    font-weight: normal;
    font-style: normal;
}

@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr {
    font-family: "Noto Sans KR", sans-serif;
}

.ts-all {
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e0c3fc;
    background-image: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
}

.timeslip {
    min-height: 88vh;
    width: 100%;
}

.ts-title-container {
    background-image: url("../assets/ex-bg.png");
    background-repeat: no-repeat;
    background-attachment: center center;
    width: 99vw;
    height: 88vh;
}

.ts-title {
    color: transparent;
    font-size: 143px;
    font-family: "116watermelon";
    text-align: center;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ts-title::before {
    content: "타임슬립";
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-right: 7px solid white;
    color: #fff;
    text-shadow: 3px 3px 3px #e0c3fc;
    animation: typing 4s steps(4) infinite;
}

@keyframes typing {
    0% {
        width: 0%;
    }
    40% {
        width: 100%;
    }
    90% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

a {
    text-decoration: none;
    color: #000;
}

.ts-slide {
    width: 100%;
    min-height: 100vh;
    background-color: #000;
}

.year-container {
    display: flex;
    flex-direction: row;
    position: sticky;
    top: 0;
    width: 100%;
    height: 65px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background: #fff;
    z-index: 100;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.year-container--top-first {
    position: fixed;
    top: 75px;
    transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.year-container--top-second {
    position: fixed;
    top: 0;
}

.year-tab {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    color: #5a5a5a;
    letter-spacing: 0.1rem;
    transition: all 0.5s ease;
    font-family: "poppinsM";
    font-weight: 600;
    font-size: 0.8rem;
    scroll-behavior: smooth;
}

.year-tab:hover {
    color: white;
    background: #8ec5fc;
    transition: all 0.5s ease;
}

.year-tab-slider {
    position: absolute;
    bottom: 0;
    width: 0;
    height: 6px;
    background: #8ec5fc;
    transition: left 0.3s ease;
}

@media (min-width: 800px) {
    .year-tab {
        font-size: 1rem;
    }
}

#tab-20 {
    position: relative;
    overflow: hidden;
}

.work {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 600;
    font-size: 4.7vh;
    color: white;
    margin-top: 7rem;
    margin-left: 5rem;
}

.work-details {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    font-size: 2.5vh;
    color: white;
    margin-top: 0.6rem;
    margin-left: 5rem;
}

.work-details-tetris {
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 200;
    font-size: 2.5vh;
    color:white;
    /* text-align: center; */
    margin-top: 0.6rem;
    margin-bottom: 5vh;
    margin-left: 5rem;
}

.work-txt {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 800;
    font-size: 9.3vh;
    color: white;
    margin-top: 12rem;
    margin-left: 6rem;
    animation: op 5s infinite;
}

@keyframes op {
    0% {
        opacity: 0.2;
    }
    80% {
        opacity: 0.2;
    }
    87% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.2;
    }
}

.virus1 {
    position: absolute;
    top: 30%;
    left: 20%;
    animation: virusMove 3s ease-in-out infinite;
}

.virus2 {
    position: absolute;
    top: 28%;
    right: 30%;
    animation: virusMove2 3s ease-in-out infinite;
}

.virus3 {
    position: absolute;
    top: 45%;
    left: 39%;
    animation: virusMove2 3s ease-in-out infinite;
}

.virus4 {
    position: absolute;
    top: 65%;
    right: 35%;
    animation: virusMove 3s ease-in-out infinite;
}

.virus5 {
    position: absolute;
    top: 35%;
    right: 10%;
    animation: virusMove2 3s ease-in-out infinite;
}

.virus6 {
    position: absolute;
    top: 50%;
    left: 5%;
    animation: virusMove2 3s ease-in-out infinite;
}

.virus7 {
    position: absolute;
    top: 70%;
    left: 24%;
    animation: virusMove 3s ease-in-out infinite;
}

.virus8 {
    position: absolute;
    top: 70%;
    right: 15%;
    animation: virusMove 3s ease-in-out infinite;
}

.work-sub {
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 200;
    font-size: 2vh;
    color: rgb(139, 139, 139);
    margin-top: 1rem;
    margin-right: 4rem;
    text-align: right;
}

@keyframes virusMove {
    30% {
        margin-left: 5px;
        margin-top: 5px;
    }
    65% {
        margin-left: 0;
        margin-top: 0;
        margin-bottom: 5px;
        margin-right: 5px;
    }
    100% {
        margin-bottom: 0;
        margin-right: 0;
    }
}

@keyframes virusMove2 {
    40% {
        margin-bottom: 6px;
        margin-left: 5px;
    }
    74% {
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 5px;
        margin-top: 5px;
    }
    100% {
        margin-right: 0;
        margin-top: 0;
    }
}

#tab-10 {
    position: relative;
    overflow: hidden;
}

.phone-img {
    background-image: url("../assets/smartphone.png");
    background-repeat: no-repeat;
    background-attachment: center center;
    width: 350px;
    height: 490px;
    position: absolute;
    right: 5%;
    bottom: 1%;
}

#slideshow {
    text-align: center;
    overflow: hidden;
    height: 357px;
    width: 227px;
    margin-left: 0.8rem;
    /* margin-bottom: rem; */
    margin-top: 3.34rem;
}

.slide-wrapper {
    width: 908px;
    animation: slide 20s ease infinite;
}

.slide {
    float: left;
    height: 357px;
    width: 227px;
}

.slide-img {
    height: 357px;
    width: 227px;
}

@keyframes slide {
    30% {
        margin-left: 0px;
    }
    40% {
        margin-left: -227px;
    }
    50% {
        margin-left: -227px;
    }
    60% {
        margin-left: -454px;
    }
    70% {
        margin-left: -454px;
    }
    80% {
        margin-left: -681px;
    }
    90% {
        margin-left: -681px;
    }
}

.mickey_mp3 {
    z-index: 1;
    position: relative;
    width: 230px;
    height: 230px;
    margin: 0 auto;
    margin-top: 18vh;
}

.small_ball_left {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 4.2vh;
    font-family: "Noto Sans KR", sans-serif;
    width: 45%;
    height: 45%;
    border-radius: 50%;
    position: absolute;
    z-index: 10;
    transform: translate(-50%, -50%);
    top: 0;
    left: 0;
    background: radial-gradient(
        circle at 50% 250%,
        #b33c65 30%,
        #a42f58 150%,
        #000000 100%
    );
}
.small_ball_left::before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(
        circle at 50% 0px,
        #f3abcb,
        rgba(255, 255, 255, 0) 33%
    );
    filter: blur(5px);
}
.volume_plus {
    float: left;
    opacity: 50%;
    margin: 8.8vh 0 0 1.4vw;
    transform: rotate(-45deg);
    cursor: pointer;
}

.volume_plus:hover {
    opacity: 1;
    cursor: pointer;
}

.volume_minus {
    float: right;
    opacity: 50%;
    margin: 1.3vh 1.5vw 0 0;
    transform: rotate(-45deg);
    cursor: pointer;
    z-index: 3;
}

.volume_minus:hover {
    opacity: 1;
    cursor: pointer;
}

.small_ball_right {
    text-align: center;
    color: rgb(255, 255, 255);
    font-size: 4.2vh;
    font-family: "Noto Sans KR", sans-serif;
    position: absolute;
    z-index: 10;
    width: 45%;
    height: 45%;
    transform: translate(50%, -50%);
    top: 0;
    right: 0;
    border-radius: 50%;
    background: radial-gradient(
        circle at 50% 250%,
        #b33c65 30%,
        #a42f58 150%,
        #000000 100%
    );
}
.small_ball_right:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(
        circle at 50% 0px,
        #f3abcb,
        rgba(255, 255, 255, 0) 33%
    );
    filter: blur(5px);
    z-index: 2;
}

.before {
    float: left;
    width: 15px;
    position: relative;
    opacity: 50%;
    margin: 3vh 0 0 1.9vw;
    transform: rotate(45deg);
    cursor: pointer;
}

.before>img{
    margin-top: 2vh;
}

.before:hover{
    opacity: 1;
    cursor: pointer;
}

.after {
    float: right;
    width: 15px;
    opacity: 50%;
    margin: 10.3vh 1.5vw 0 0;
    transform: rotate(225deg);
    cursor: pointer;
}

.after:hover{
    opacity: 1;
    cursor: pointer;
}

.big_ball {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background: radial-gradient(
        circle at 50% 250%,
        #b33c65 30%,
        #a42f58 150%,
        #000000 100%
    );
}
.big_ball:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(
        circle at 50% 0px,
        #f3abcb,
        rgba(255, 255, 255, 0) 33%
    );
    filter: blur(5px);
    z-index: 1;
}

/* .big_ball .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 50% 50%, rgba(83, 83, 83, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    transform: rotateX(90deg) translateZ(-150px);
    z-index: -1;
} don't delete 노삭제 부탁드려요 */

.play_txt {
    /* z-index: 2; */
    width: fit-content;
    height: fit-content;
    font-family: "poppinsL";
    font-size: 1.3rem;
    white-space: nowrap;
    color: transparent;
    margin: 0 auto;
    text-align: center;
    margin-top: 45vh;
    position: absolute;
    transform: translate(-22%, -20%);
}

.play_txt:before {
    content: "Music is my life~ Click the 'HAPPYPLE'........";
    text-align: center;
    margin: 0 auto;
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    color: #ffffff;
    overflow: hidden;
    border-right: 3px solid white;
    animation: typing 12s steps(35) infinite;
}

@keyframes typing {
    0% {
        width: 0%;
    }
    50% {
        width: 100%;
    }
    100% {
        width: 0%;
    }
}

.random-img {
    width: auto;
    height: 56vh;
    margin: 0 auto;
    margin-top: 6.5vh;
    display: block;
    text-align: center;
}

.random-img img {
    width: 100%;
    display: inline;
}

#tab-80 {
    position: relative;
    overflow: hidden;
}

.select1 {
    position: absolute;
    top: 29.8%;
    left: 27.2%;
    z-index: 1;
}

.select2 {
    position: absolute;
    top: 29.8%;
    left: 31.1%;
    z-index: 0;
}

.select3 {
    position: absolute;
    top: 29.8%;
    left: 35%;
}

.select4 {
    position: absolute;
    top: 29.8%;
    left: 38.9%;
}

.select5 {
    position: absolute;
    top: 29.8%;
    left: 42.8%;
}

.select6 {
    position: absolute;
    top: 29.8%;
    left: 46.7%;
}

.select7 {
    position: absolute;
    top: 29.8%;
    left: 50.6%;
}

.select8 {
    position: absolute;
    top: 29.8%;
    left: 54.5%;
}

.select9 {
    position: absolute;
    top: 29.8%;
    left: 58.4%;
}

.select10 {
    position: absolute;
    top: 29.8%;
    left: 62.3%;
}

.select11 {
    position: absolute;
    top: 29.8%;
    left: 66.2%;
}

.select12 {
    position: absolute;
    top: 29.8%;
    left: 70.1%;
}

.select1-1 {
    position: absolute;
    top: 32.4%;
    left: 27.2%;
}

.select2-1 {
    position: absolute;
    top: 32.4%;
    left: 31.1%;
}

.select3-1 {
    position: absolute;
    top: 32.4%;
    left: 35%;
}

.select4-1 {
    position: absolute;
    top: 32.4%;
    left: 38.9%;
}

.select5-1 {
    position: absolute;
    top: 32.4%;
    left: 42.8%;
}

.select6-1 {
    position: absolute;
    top: 32.4%;
    left: 46.7%;
}

.select7-1 {
    position: absolute;
    top: 32.4%;
    left: 50.6%;
}

.select8-1 {
    position: absolute;
    top: 32.4%;
    left: 54.5%;
}

.select9-1 {
    position: absolute;
    top: 32.4%;
    left: 58.4%;
}

.select10-1 {
    position: absolute;
    top: 32.4%;
    left: 62.3%;
}

.select11-1 {
    position: absolute;
    top: 32.4%;
    left: 66.2%;
}

.select12-1 {
    position: absolute;
    top: 32.4%;
    left: 70.1%;
}

.select1-2 {
    position: absolute;
    top: 35.1%;
    left: 27.2%;
}

.select2-2 {
    position: absolute;
    top: 35.1%;
    left: 31.1%;
}

.select3-2 {
    position: absolute;
    top: 35.1%;
    left: 35%;
}

.select4-2 {
    position: absolute;
    top: 35.1%;
    left: 38.9%;
}

.select5-2 {
    position: absolute;
    top: 35.1%;
    left: 42.8%;
}

.select6-2 {
    position: absolute;
    top: 35.1%;
    left: 46.7%;
}

.select7-2 {
    position: absolute;
    top: 35.1%;
    left: 50.6%;
}

.select8-2 {
    position: absolute;
    top: 35.1%;
    left: 54.5%;
}

.select9-2 {
    position: absolute;
    top: 35.1%;
    left: 58.4%;
}

.select10-2 {
    position: absolute;
    top: 35.1%;
    left: 62.3%;
}

.select11-2 {
    position: absolute;
    top: 35.1%;
    left: 66.2%;
}

.select12-2 {
    position: absolute;
    top: 35.1%;
    left: 70.1%;
}

.select1-3 {
    position: absolute;
    top: 37.8%;
    left: 27.2%;
}

.select2-3 {
    position: absolute;
    top: 37.8%;
    left: 31.1%;
}

.select3-3 {
    position: absolute;
    top: 37.8%;
    left: 35%;
}

.select4-3 {
    position: absolute;
    top: 37.8%;
    left: 38.9%;
}

.select5-3 {
    position: absolute;
    top: 37.8%;
    left: 42.8%;
}

.select6-3 {
    position: absolute;
    top: 37.8%;
    left: 46.7%;
}

.select7-3 {
    position: absolute;
    top: 37.8%;
    left: 50.6%;
}

.select8-3 {
    position: absolute;
    top: 37.8%;
    left: 54.5%;
}

.select9-3 {
    position: absolute;
    top: 37.8%;
    left: 58.4%;
}

.select10-3 {
    position: absolute;
    top: 37.8%;
    left: 62.3%;
}

.select11-3 {
    position: absolute;
    top: 37.8%;
    left: 66.2%;
}

.select12-3 {
    position: absolute;
    top: 37.8%;
    left: 70.1%;
}

.select1-4 {
    position: absolute;
    top: 40.5%;
    left: 27.2%;
}

.select2-4 {
    position: absolute;
    top: 40.5%;
    left: 31.1%;
}

.select3-4 {
    position: absolute;
    top: 40.5%;
    left: 35%;
}

.select4-4 {
    position: absolute;
    top: 40.5%;
    left: 38.9%;
}

.select5-4 {
    position: absolute;
    top: 40.5%;
    left: 42.8%;
}

.select6-4 {
    position: absolute;
    top: 40.5%;
    left: 46.7%;
}

.select7-4 {
    position: absolute;
    top: 40.5%;
    left: 50.6%;
}

.select8-4 {
    position: absolute;
    top: 40.5%;
    left: 54.5%;
}

.select9-4 {
    position: absolute;
    top: 40.5%;
    left: 58.4%;
}

.select10-4 {
    position: absolute;
    top: 40.5%;
    left: 62.3%;
}

.select11-4 {
    position: absolute;
    top: 40.5%;
    left: 66.2%;
}

.select12-4 {
    position: absolute;
    top: 40.5%;
    left: 70.1%;
}

.select1-5 {
    position: absolute;
    top: 43.1%;
    left: 27.2%;
}

.select2-5 {
    position: absolute;
    top: 43.1%;
    left: 31.1%;
}

.select3-5 {
    position: absolute;
    top: 43.1%;
    left: 35%;
}

.select4-5 {
    position: absolute;
    top: 43.1%;
    left: 38.9%;
}

.select5-5 {
    position: absolute;
    top: 43.1%;
    left: 42.8%;
}

.select6-5 {
    position: absolute;
    top: 43.1%;
    left: 46.7%;
}

.select7-5 {
    position: absolute;
    top: 43.1%;
    left: 50.6%;
}

.select8-5 {
    position: absolute;
    top: 43.1%;
    left: 54.5%;
}

.select9-5 {
    position: absolute;
    top: 43.1%;
    left: 58.4%;
}

.select10-5 {
    position: absolute;
    top: 43.1%;
    left: 62.3%;
}

.select11-5 {
    position: absolute;
    top: 43.1%;
    left: 66.2%;
}

.select12-5 {
    position: absolute;
    top: 43.1%;
    left: 70.1%;
}

.card-wrapper {
    width: 100%;
    min-height: 200vh;
    background-color: #000;
}

.contentWrap {
    width: fit-content;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    perspective: 1000px;
    visibility: hidden;
    opacity: 0;
    animation: move 5s infinite linear alternate;
    margin-top: 100vh;
    margin-left: 70vh;
}
  
.active {
    visibility: visible;
    opacity: 1;
}
  
.front {
    position: relative;
    width: 400px;
    height: 250px;
    background: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
    transform: rotateX(-10deg);
    transform-origin: bottom center;
    transform-style: preserve-3d;
    perspective: 800px;
    border-radius: 10px 10px 0 0;
}
  
.front .kiki {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 20vh;
    background: url(../assets/text.png) center top/200px no-repeat;
    transform: translate3d(6px, 45px, 60px);
}
  
.front .sun {
    position: absolute;
    top: 7%;
    left: 0;
    width: 50%;
    height: 15vh;
    background: url(../assets/stars.png) center top/160px no-repeat;
    transform: translate3d(30px, 15px, 10px);
}
  
.front .cloud1 {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    background: url(../assets/cloud.png) center center/40px no-repeat;
    transform: translate3d(300px, 30px, 30px);
    opacity: .8;
    z-index: 10;
}
  
.front .cloud2 {
    position: absolute;
    top: 0;
    width: 100px;
    height: 100px;
    background: url(../assets/cloud.png) center center/36px no-repeat;
    transform: translate3d(-20px, 90px, 40px);
    opacity: .8;
    z-index: 10;
}
  
.front .m1 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90%;
    height: 200px;
    background: url(../assets/m3.png) right bottom/75% no-repeat;
    transform: translate3d(-8px, 0, 26px);
}
  
.front .m2 {
    position: absolute;
    bottom: 22%;
    right: 10%;
    width: 20%;
    height: 12vh;
    background: url(../assets/virus3.png) right bottom/100% no-repeat;
    transform: translate3d(-10px, 0, 45px);
}
  
.front .snow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    background: url(../assets/tw.png) center bottom/90% no-repeat;
    transform: translate3d(0, 0, 35px);
    opacity: .7;
}
  
.front .m3 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    height: 10vh;
    background: url(../assets/tetris.png) left bottom/30% no-repeat;
    transform: translate3d(-10px, -5px, 60px);
}
  
.front .cow {
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 0;
    left: 0;
    z-index: 10;
    background: url(../assets/virus.png) center center/100% no-repeat;
    transform: translate3d(65px, -9px, 48px);
}
  
.back {
    position: relative;
    width: 400px;
    height: 200px;
    background: linear-gradient(180deg, #9e9e9e, #fff);
    border-radius: 0 0 10px 10px;
    transform: rotateX(60deg);
    transform-origin: top center;
    transform-style: preserve-3d;
    perspective: 800px;
}
  
.back h3 {
    position: absolute;
    font-size: 2vh;
    text-align: right;
    font-weight: 100;
    right: 0;
    bottom: 0;
    transform-origin: bottom;
    transform: translate3d(-20px, 0px, 2px);
    color: #000;
    font-family: 'Handlee', cursive, "Apple SD Gothic Neo", arial;
}
  
.back .msg {
    font-family: "Pretty_Left_handed";
    position: absolute;
    display: block;
    width: 100%;
    font-size: 4vh;
    text-align: center;
    font-weight: 100;
    top: 8vh;
    left: 0px;
    transform-origin: bottom;
    transform: translate3d(0px, 0px, 2px);
    color: rgb(31, 31, 31);
}


@keyframes move {
    0% {
        transform: rotateX(-20deg);
    }

    20% {
        transform: rotateY(20deg);
    }

    40% {
        transform: rotateX(-10deg);
    }

    60% {
        transform: rotateY(-20deg);
    }

    80% {
        transform: rotateX(-10deg);
    }

    100% {
        transform: rotateY(20deg);
    }
}