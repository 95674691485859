@font-face {
    font-family: 'poppinsM';
    src: url('../font/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'SDSamliphopangche_Basic';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Basic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBMPlexSansKR-Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
  
.bs-all {
    max-width: 1920px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e0c3fc;
    background-image: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
}
  
.FAQ {
    overflow: hidden;
    min-height: 80.7vh;
    height: fit-content;
    width: 75%;
    margin-top: 2.8rem;
}
  
.FAQ-text {
    color: #5a5a5a;
    font-size: 22px;
    font-weight: 700;
    font-family: 'poppinsM';
    margin: 2rem;
}

.FAQ-container {
    background-color: white;
    padding: 5% 5% 11% 5%;
    width: auto;
    height: fit-content;
    min-height: 81vh;
    border-radius: 28px 28px 0 0;
    text-align: center;
}

.FAQ-header {
    font-size: 28px;
    font-family: 'SDSamliphopangche_Basic';
    margin: 2rem 0 2rem 0;
}

.qna-container {
    padding: 0 12% 11% 8%;
}

.question {
    color: rgb(34, 34, 34);
    font-size: 23px;
    font-family: 'SDSamliphopangche_Basic';
    margin: 5rem 0 0 2rem;
    line-height: 1.3em;
    text-align: left;
}

.answer {
    font-size: 17px;
    color: rgb(51, 51, 51);
    font-family: 'IBMPlexSansKR-Light';
    line-height: 1.8em;
    margin: 1rem 0 0 3.8rem;
    text-align: left;
    white-space : pre-wrap;
}

.qna-button {
    font-family: 'SDSamliphopangche_Basic';
    font-size: 18px;
    text-align: center;
    padding: 15px 35px;
    border-radius: 50px;
    color:rgb(255, 255, 255);
    background-color: #b1b1b1;
    width: 12vw;
    height: 8vh;
    margin: 0 auto;
    margin-top: 2rem;
    cursor: pointer;
}

.qna-text1 {
    color: #ffffff;
}

.qna-button:hover {
    background-color: #8ec5fc;
    color: rgb(255, 255, 255);
}

.qna-button:hover>a {
    background-color: #8ec5fc;
    color: rgb(0, 0, 0);
}
