@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);

.notosanskr { 
    font-family: 'Noto Sans KR', sans-serif;
}

@font-face {
    font-family: 'NeoDunggeunmo';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.3/NeoDunggeunmo.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.abc .def {
    position:fixed;
    top:3vh;
    right:0;
    bottom:0;
    left:0;
    width:45.3vw;
    height:29.5vh;
    z-index:100;
    background-color: rgba(0, 0, 0, 0.4);
}

.eee{
    z-index:100;
    position:fixed;
    top:8.6vh;
    left: 28vh;
    display:inline-block;
    vertical-align:middle;
    width: 18vw;
    height: 18vh;
    background-color:rgb(197, 197, 197);
    white-space:normal;
    word-break:break-word;
    border-radius:5px;
    box-sizing:border-box;
    box-shadow: 0.3vh 0.3vh rgba(77, 77, 77, 0.7);
    
}

.lmn {
    text-align:center;
    font-size: 3.8vh;
    font-weight: 600;
    color: rgb(37, 37, 37);
    font-family: 'Noto Sans KR', sans-serif;
    width: 9vw;
    height: 18vh;
    padding-top: 2.9vw;
    border-right: dashed 2px rgb(172, 172, 172);
}

.ert {
    width: fit-content;
    height: fit-content;
}

#mmm {
    width: 2.4vw;
    height: 3vh;
    background-color: transparent;
    position: relative;
    top: 3.5vh;
    left: 0.3vh;
    z-index: 1;
    cursor: pointer;
}

.hijk {
    width: 2.5vw;
    height: 5vh;
    background: transparent;
    border:none;
    box-shadow:none;
    border-radius:0;
    padding:0;
    cursor: pointer;
    float: right;
    font-family: 'NeoDunggeunmo';
    font-size: 3vh;
    color: rgb(80, 80, 80);
    padding-top: 1.5vh;
    padding-right: 0.5vh;
}

.qqq {
    width: 2.7vw;
    height: auto;
}