@font-face {
    font-family: 'poppinsM';
    src: url('../font/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'poppinsL';
    src: url('../font/Poppins-Light.ttf');
}


@font-face {
    font-family: 'IBMPlexSansKR-Light';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/IBMPlexSansKR-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.bs-all {
    min-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e0c3fc;
    background-image: linear-gradient(147deg, #e0c3fc 0%, #8ec5fc 74%);
}

.exhibition {
    min-height: 88vh;
    max-width: fit-content;
    width: 100%;
}

.exhibition-text {
    color: #ffffff;
    font-size: 28px;
    font-family: 'SDSamliphopangche_Basic';
    margin: 4.4rem 0 2rem 0;
    text-align: center;
}

.ex-dropdown {
    float: right;
    position: relative;
    margin-right: 8vw;
}

.ex-dropbtn {
    width: 8vw;
    height: 5.7vh;
    font-family: 'IBMPlexSansKR-Light';
    font-size: 2.2vh;
    font-weight: 800;
    background-color: rgb(255, 255, 255, 0.42);
    color: rgb(68, 68, 68);
    border-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 7px 13px 0px rgba(58, 57, 57, 0.2);

}

.ex-dropdown:hover .ex-dropdown-content {
    display: block;
}

.ex-dropdown:hover .ex-dropbtn {
    background-color: rgb(255, 255, 255, 0.88);
}

.ex-dropdown-content {
    font-family: 'IBMPlexSansKR-Light';
    font-size: 2.2vh;
    font-weight: 800;
    text-align: center;
    display: none;
    position: absolute;
    background-color: rgb(255, 255, 255, 0.5);
    color: rgb(68, 68, 68);
    min-width: 8vw;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.ex-dropdown-content a {
    color: rgb(68, 68, 68);
    padding: 1.3vh 0.8vw;
    text-decoration: none;
    display: block;
    max-height: 5.9vh;
    text-align: center;
}

.ex-dropdown-content a:hover {
    background-color: rgb(255, 255, 255, 0.88);
    border-radius: 5px;
}

.wrapper {
    position: relative;
    display: flex;
    width: 88%;
    height: fit-content;
    justify-content: space-around;
    margin: 0 auto;
    margin-top: 20vh;
}

.card {
    width: 27vw;
    height: 38vh;
    border-radius: 15px;
    padding: 1.5rem;
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    transition: 0.4s ease-out;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
}

.card:hover {
    transform: translateY(15px);
}

.card:hover:before {
    opacity: 1;
}

.card:hover .info {
    opacity: 1;
    transform: translateY(0px);
}

.card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background: rgba(12, 12, 12, 0.9);
    z-index: 2;
    transition: 0.5s;
    opacity: 0;
}

.card img {
    position: absolute; top:0; left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
}

.card .info {
    position: relative;
    z-index: 3;
    color: white;
    opacity: 0;
    transform: translateY(30px);
    transition: 0.5s;
}

.card .info h1 {
    margin: 0px;
    font-family: 'poppinsM';
    font-size: 5.3vh;
}

.card .info p {
    letter-spacing: 1px;
    font-size: 2vh;
    margin-top: 3vh;
    font-family: 'poppinsL';
}

.card .info .info-details {
    letter-spacing: 1px;
    font-size: 14px;
    margin-top: 8px;
    font-family: 'IBMPlexSansKR-Light';
}

.card .info button {
    width: 8vw;
    outline: none;
    border: none;
    padding: 10px 15px;
    border-radius: 50px;
    background: white;
    color: black;
    cursor: pointer;
    font-weight: 500;
    font-family: 'poppinsM';
    float: right;
}

#open-btn:hover {
    background-image: linear-gradient(147deg, #9d69ce 0%, #70a5da 74%);
    color: white;
}

#close-btn:hover {
    background-color: #5a5a5a;
    color: white;
}